.walinkLogo {
  margin-right: 1rem;
  padding-right: 1rem;
  width: 150px;
  border-right: 1px solid #333;
}

.coverContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 334px) {
  .walinkLogo {
    border-right: none;
  }
}
