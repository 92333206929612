.exampleCard{
  text-align: center;
  padding: 1.5rem;
  margin: 1rem;
  max-width: 350px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 4px;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}