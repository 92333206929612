.explanationContainer {
  display: flex;
  justify-content: center;

  flex-wrap: wrap;
  max-width: 960px;
}

.explanationDescription {
  padding: 1rem;
  border-right: 1px solid #333;
  display: flex;
  align-items: center;
  max-width: 400px;
}

.explanationBullets {
  padding: 1rem;
  max-width: 400px;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 863px) {
  .explanationDescription {
    border-right: none;
  }
}
