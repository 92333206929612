.benefitsContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.benefits {
  width: 90%;
  min-height: 250px;
  display: flex;
  overflow-x: auto;
}

.benefitCard {
  padding: 1rem;
  margin: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 4px;
  border-radius: 10px;
  flex: 1;
  flex-shrink: 0;
}

@media only screen and (max-width: 1083px) {
  .benefitsContainer {
    width: 100%;
  }

  .benefits {
    padding: 1rem;
    width: 100%;
  }

  .benefitCard {
    min-width: 80%;
  }
}
