.whatsAppFactsContainer {
  display: flex;
  justify-content: center;

  flex-wrap: wrap;
  max-width: 960px;
}
.factContainer {
  margin: 1rem;
}
.factContainer h3 {
  text-align: center;
}

.factInfo {
  padding: 1rem;
  margin: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 4px;
  border-radius: 10px;
  flex: 0 0 auto;
  /* display: flex;
  justify-content: center; */
}
