.presentationContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.description {
  max-width: 90vh;
  margin: 1rem;
}

.description p {
  margin-top: 2rem;
}

.whatsappLogo {
  max-width: 85px;
  margin: 15px;
}
