.historyContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.statistics {
  /* max-width: 300px; */
  /* position: relative; */
  padding: 1rem;
  display: flex;
  white-space: nowrap;
  overflow-x: auto;
  flex-wrap: nowrap;
  /* justify-content: center; */
}

.yearContainer {
  margin: 1rem;
  padding: 2rem;
  border-right: 1px solid #333;
}

.numberInfo {
  padding: 1rem;
  margin: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 4px;
  border-radius: 10px;
  flex: 0 0 auto;
  /* display: flex;
  justify-content: center; */
}

.numberContainer {
  margin: 1rem;
}
.numberContainer h3 {
  text-align: center;
}

@media only screen and (max-width: 1083px) {
  .yearContainer {
    border-right: none;
    margin: 0px;
    padding: 0;
  }
  .statistics {
    max-width: 300px;
  }
}
