@keyframes jump {
  0% {
    bottom: 10px;
  }
  50% {
    bottom: 30px;
  }
  100% {
    bottom: 10px;
  }
}

.green-gradient {
  background-image: linear-gradient(
    to right top,
    #075e54,
    #007b62,
    #00986b,
    #00b66c,
    #25d366
  );
}

.titleContainer {
  margin: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slide-content {
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.slide-content h1 {
  text-align: center;
}

.swiper-container {
  height: 100vh;
  width: 100%;
}

.swiper-slide {
  background-color: #fff;
  min-height: 100vh;
  width: 100%;
}
.swiper-pagination-progressbar-fill {
  background-color: green !important;
}

.serviceCard {
  padding: 1.5rem;
  margin: 1rem;
  max-width: 350px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 4px;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.price {
  color: #00986b;
  font-size: 1.5rem;
}

.servicesContainer {
  display: flex;
  flex-wrap: wrap;
}
.arrowContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 1000;
  bottom: 20px;
  animation: jump 3s infinite;
}

.downArrow {
  cursor: pointer;
  height: 50px;
}

@media only screen and (max-width: 600px) {
  .slide-content {
    height: 92vh;
  }
  .arrowContainer {
    position: fixed;
    bottom: 50px;
  }
}
